<template>
    <div class="container my-4">
        <div class="row">
            <div class="col-md-12 mb-3">
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <h4 class="fw-bold mb-0">{{ survey.title }}</h4>
                        Survey code - {{ survey.code }}
                    </div>
                    <div>
                        <div class="btn-group">
                            <button type="button" class="btn btn-outline-secondary" @click="viewMode = 'list'">List
                                View
                            </button>
                            <button type="button" class="btn btn-secondary" @click="viewMode = 'table'">Table View
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="sticky-md-top" style="top: 20px">
                    <div class="mb-2">
                        <input type="text" class="form-control" placeholder="Search by name or code..."
                               v-model="searchQuery" @input="handleSearch">
                    </div>
                    <ol class="list-group list-group-flush">
                        <li v-for="i in paginatedData" :key="`key-`+i.id"
                            class="list-group-item d-flex justify-content-between align-items-center"
                            :class="(res && res.id === i.id) ? 'active' : ''" style="cursor: pointer" @click="res=i">
                            <div class="ms-2 me-auto">
                                <div class="fw-bold">{{ i.name }}</div>
                                {{ i.code }}
                            </div>
                            <span class="badge border text-dark rounded-pill">{{ i.date }}</span>
                        </li>
                    </ol>
                    <nav aria-label="Page navigation">
                        <ul class="pagination justify-content-between mt-4">
                            <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                <a class="page-link" href="#" aria-label="Previous" @click="prevPage">
                                    <span aria-hidden="true">&laquo; Previous</span>
                                </a>
                            </li>
                            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                <a class="page-link" href="#" aria-label="Next" @click="nextPage">
                                    <span aria-hidden="true">Next &raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div class="col-md-8">
                <div class="bg-white p-3 mb-2">
                    Response {{ res.code }}
                </div>
                <div class="accordion" id="accordionPanelsStayOpenExample">
                    <div v-for="a in currentRespondentData" :key="a.id" class="accordion-item">
                        <h4 class="accordion-header" :id="'panelsStayOpen-heading-' + a.question_id">
                            <button class="accordion-button" type="button"
                                    :data-bs-toggle="'#panelsStayOpen-collapse-' + a.question_id" aria-expanded="true"
                                    :aria-controls="'panelsStayOpen-collapse-' + a.question_id">
                                {{ questions.find(q => q.id === a.question_id).title }}
                            </button>
                        </h4>
                        <div :id="'panelsStayOpen-collapse-' + a.question_id" class="accordion-collapse collapse show"
                             :aria-labelledby="'panelsStayOpen-heading-' + a.question_id">
                            <div class="accordion-body">
                                {{ a.answer }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SurveyRespondentsV2",
    props: ['code'],
    data() {
        return {
            res: null,
            survey: null,
            questions: [],
            viewMode: 'list', // Default view mode
            searchQuery: '',      // Search query input
            allData: [],          // All survey respondent data
            filteredData: [],     // Filtered data for search
            currentPage: 1,       // Current page number
            perPage: 10,         // Number of items per page
        }
    },
    computed: {
        totalPages() {
            return Math.ceil(this.filteredData.length / this.perPage);
        },
        paginatedData() {
            const start = (this.currentPage - 1) * this.perPage;
            return this.filteredData.slice(start, start + this.perPage);
        },
        currentRespondentData() {
            return this.res.json.filter(a => {
                return a.answer !== null
            });
        }
    },
    methods: {
        fetchData() {
            // Fetch data from API
            this.$http.get("/api/survey/respondents/" + this.code)
                .then(response => {
                    this.allData = response.data.data.respondents;
                    this.questions = response.data.data.questions;
                    this.survey = response.data.data.survey;

                    this.filteredData = this.allData.slice(); // Initially, set filtered data to all data
                    this.res = this.allData[0]; // Example: using first respondent's data
                })
                .catch(error => {
                    this.showError(error); // Handle error
                });
        },
        showError(error) {
            // Implement error handling logic
            console.error('Error fetching data:', error);
        },
        changePage(page) {
            this.currentPage = page;
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        handleSearch() {
            // Filter data based on search query
            if (this.searchQuery.trim() === '') {
                this.filteredData = this.allData.slice(); // Reset filtered data
            } else {
                this.filteredData = this.allData.filter(item =>
                    (item.name && item.name.toLowerCase().includes(this.searchQuery.toLowerCase())) ||
                    (typeof item.code === 'number' && item.code.toString().includes(this.searchQuery))
                );
            }
            this.currentPage = 1; // Reset to first page after search
        },
        getAnswer(respondentId, questionId) {
            const respondent = this.res.json.find(res => res.respondent_id === respondentId);
            if (respondent) {
                const answer = respondent.answers.find(ans => ans.question_id === questionId);
                return answer ? answer.answer : '-';
            }
            return '-';
        }
    },
    watch: {
        code() {
            this.fetchData(); // Fetch data when code prop changes
        }
    },
    mounted() {
        this.fetchData(); // Fetch data when component is mounted
    }
}
</script>

<style scoped>
/* Add scoped styles here if needed */
.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0b2559;
    border-color: #0b2559;
}

.list-group-item.active .badge {
    color: #fff !important;
}
</style>
